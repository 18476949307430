import React, { useState } from "react";
import { Parallax } from "react-parallax";
import Layout from "../../components/layouts";
import bgImage from "../../images/header-intern.jpg";

const SignUpIntern = () => {
    const [feedback, setFeedback] = useState({
      message: "",
      messageType: "",
    });
    const [email, setEmail] = useState("");
    const [fName, setFname] = useState("");
    const [lName, setLname] = useState("");
    const [phone, setPhone] = useState("");
    const [skype, setSkype] = useState("");
    const [facebook, setFacebook] = useState("");
    const [resume, setResume] = useState("");
    const [source, setSource] = useState("");
    const [why, setWhy] = useState("");

    const handleSubmit = (e) => {
      e.preventDefault();
      if (fName !== '' && lName !== '' && email !== '') {
        const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        const form = document.querySelector('form');
        const data = new FormData(form);
        if (!regex.test(email)) {
            setFeedback({
                message: 'Please enter a valid E-mail',
                messageType: 'error'
            });
            return;
        } else {
            fetch("https://globalinheritance.us2.list-manage.com/subscribe/post-json?&c=?", {
              method: "POST",
              body: data,
              mode: 'no-cors',
            }).then(async (response) => {
              if (response.statusText === 'success') {
                setFeedback({
                    message: 'Thank you! Your information has been sent',
                    messageType: 'success'
                });
              } else if (response.statusText === 'error'){
                const text = await response.text();
                  setFeedback({
                      message:text,
                      messageType: 'error'
                  });
              }
            });
          }
    } else {
        setFeedback({
            message: 'Please fill out all fields',
            messageType: 'error'
        });
    }
    };

    const handleExpand = (e) => {
      const expandedElements = document.getElementsByClassName("active");
      for (let i = 0; i < expandedElements.length; i++) {
        expandedElements[i].classList.remove("active");
      }

      e.target.parentElement.classList.add("active");
    };

    return (
      <Layout navBg>
        <div className="signup-page">
        <div className="signup-inner">
        <Parallax className="page-header" bgImage={bgImage} strength={400}>
              <div style={{height: '100vh', maxHeight: '550px'}}>
                  <div className="page-header-overlay" />
                  <h1 className="page-header-title white">Internships</h1>
              </div>
          </Parallax>
          <div className="signup-body">
          <div className="signup-intro">
              <p>Are you the type of person who wants to solve problems? Are you interested in working with the best in the worlds of film, music, sustainability, social good, advertising and art? Do you excel in a fast moving, creative working environment? Are you ready and able to take on real responsibility and keen to gain valuable experience?  Do you have the energy, creativity, and enthusiasm to get things done? If you were nodding your head while reading – sign up for a Global Inheritance internship!</p>

              <p>Over the past 20 years, Global Inheritance has produced creative, interactive programming focused on improving planetary health. We partner with large events, institutions, and seasoned creatives to empower their communities - producing over 400 programs at major music festivals including the Coachella Music & Arts Festival since 2004.</p>

              <p>Global Inheritance is looking for highly motivated and extraordinary candidates to contribute to our organization across multiple platforms – in office, onsite at events, and online. </p>
          </div>
          {/* <div className="signup-accordion-container">
              <div className="signup-accordion" onClick={(e) => handleExpand(e)}>
                  <div className="signup-accordion-header">Digital Content Strategy Intern (Remote or Office Internship)</div>
                  <div className="signup-accordion-body">
                      <p><strong>Start Date - Dec 2022/Jan 2023</strong></p>
                      <p>Global Inheritance is looking for a Digital Content Strategy Intern (Remote or Los Angeles Office Internship) to join our team.</p>

                      <p>The ideal applicant will have strong knowledge in all aspects of digital media, analytical abilities, creative content ideas, and a dynamic personality that fits well with artists/partners/Global Inheritance team members.</p>

                      <p>Global Inheritance is a non-profit organization that develops experiences to educate and inspire individuals to take action around environmental and social issues. Our programs use the power of creativity and interactivity to empower people to join our mission in making this planet a better place for future generations. We are looking for a bright, creative, motivated, and organized individual to join the organization and make an impact.</p>

                      <p>
                      The successful candidate will be responsible for developing, coordinating, and executing a content strategy and maintaining a content calendar across all of Global Inheritance’s digital communications (including Instagram, Facebook, Twitter, YouTube, and our digital newsletters).
                      </p>

                      <ul className="signup-accordion-body-list">
                          <p>Responsibilities include but are not limited to:</p>
                          <li>Designing and taking ownership of a content strategy rebranding - with a focus on Instagram and newsletters</li>
                          <li>Maintaining a social media content calendar</li>
                          <li>Working with and supporting Social Media Coordinators to post content and increase online engagement </li>
                          <li>Managing and monitoring the Global Inheritance social media accounts to ensure good levels of guest service and interaction with other organizations</li>
                          <li>Create, maintain and analyze reports to track social media growth, engagement, and overall success of the social media plan</li>
                          <li>Provide support to our team at live and online events</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>Desired Skills/Interests:</p>
                          <li>Experience in managing successful social media accounts</li>
                          <li>Working knowledge of  the latest social media trends</li>
                          <li>In-depth understanding of the following platforms: Facebook, Instagram, Twitter, Patreon, YouTube, and Linkedin</li>
                          <li>Basic design skills in photo editing and Canva</li>
                          <li>Extremely proactive and excellent organizational skills - not afraid to take ownership of a project</li>
                          <li>Outstanding communicator</li>
                          <li>Comfortable being in a leadership role</li>
                          <li>Interested in environmental and/or social issues</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>Perks & Benefits:</p>
                          <li>Monthly travel credit for commuting in Los Angeles (office based internships)</li>
                          <li>Behind-the-scenes access to high profile events</li>
                          <li>Cool merchandise</li>
                          <li>Opportunity for paid event work</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>What You Receive:</p>
                          <li>Real world project experience on high-impact outreach campaigns</li>
                          <li>Meet like minded people in the Live Event/NGO/Entertainment/Art worlds</li>
                          <li>Platform to showcase your creativity and sharpen your skills</li>
                          <li>Opportunity to work with high profile international partners and artists</li>
                          <li>Ability to learn from key organization members and lead projects</li>
                          <li>A crash course on producing environmental and socially focused programming at major events, college and business campuses</li>
                          <li>Internship College Credit: We will provide documentation if needed and applicable for college internship credit</li>
                      </ul>

                      <p>The expected hours are <strong>10-15 hours per week</strong>. The hours required  will increase during event weeks (typically in the Spring and Summer months)</p>

                      <p>To <strong>apply</strong> for this position, please complete the job application form <a href="https://docs.google.com/forms/d/e/1FAIpQLSeSAzYsTmJNNHWU0OPvRiOv34CLxQ5afSCwmrLgU3FBqLYZ0Q/viewform" style={{'display': 'inline-block', 'textDecoration': "underline"}} target="_blank">here</a>.</p>

                      <p><i>Please also note: This is an unpaid internship. Opportunity for pay in the future will vary on projects as well as skills/experience.</i></p>
                  </div>
              </div>
          </div>

          <div className="signup-accordion-container">
              <div className="signup-accordion" onClick={(e) => handleExpand(e)}>
                  <div className="signup-accordion-header">Coachella Music & Arts Festival Project Manager (Arts Focused) Internship</div>
                  <div className="signup-accordion-body">
                      <p><strong>Start Date - Dec 2022/Jan 2023 (Internship requires you to be present at both Coachella weekends in April 2023)</strong></p>
                      <p>Global Inheritance is looking for a Festival Project Manager Intern  (Remote or Los Angeles Office Internship) to join our team.</p>

                      <p>The ideal applicant will be passionate about designing a better world, have excellent communication skills, be highly organized, detail-oriented, and have a dynamic personality that fits well with artists/partners/Global Inheritance team members. </p>

                      <p>Global Inheritance is a non-profit organization that develops experiences to educate and inspire individuals to take action around environmental and social issues. Our programs use the power of creativity and interactivity to empower people to join our mission in making this planet a better place for future generations. We are looking for a bright, creative, motivated, and organized individual to join the organization and make an impact. </p>

                      <p>
                      The successful candidate will be responsible for being the first point of contact for our partnering artists and designers, managing deadline-driven art redesign projects, and ensuring the program is a fun and enriching experience for all participants.
                      </p>

                      <ul className="signup-accordion-body-list">
                          <p>Responsibilities include but are not limited to:</p>
                          <li>Be a professional, friendly, and efficient first point of contact for the artists - both on the phone and electronically</li>
                          <li>Review and expand our current list of potential artists</li>
                          <li>Manage the process of archiving artwork created for events, organize databases and online galleries</li>
                          <li>Manage artist schedules, deadlines, and logistics of their participation in events</li>
                          <li>After events, manage the delivery of any materials to artists / participants / partners</li>
                          <li>Provide campaign updates and information to event participants</li>
                          <li>Collaborate with social media team to develop content around promotional campaigns and featured artists</li>
                          <li>Collaborate with Production Management Team to design and develop engaging experiences for participants</li>
                          <li>Provide support to our team at live and online events</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>Desired Skills/Interests:</p>
                          <li>Extremely proactive and outstanding communicator</li>
                          <li>Excellent organizational skills - not afraid to take ownership of a project</li>
                          <li>Comfortable being in a leadership role</li>
                          <li>A practical knowledge of Adobe Creative Cloud software - especially Photoshop, Illustrator and Canva</li>
                          <li>Awareness of the current trends within art and design</li>
                          <li>Interests in Social/Environmental Activism and ARTivism</li>
                          <li>An appreciation of art is necessary, having artistic abilities is desirable</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>Perks & Benefits:</p>
                          <li>Monthly travel credit for commuting in Los Angeles (office based internships)</li>
                          <li>Behind-the-scenes access to high profile events</li>
                          <li>Cool merchandise</li>
                          <li>Opportunity for paid event work</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>What You Receive:</p>
                          <li>Real world project experience on high-impact outreach campaigns</li>
                          <li>Meet like minded people in the Live Event/NGO/Entertainment/Art worlds</li>
                          <li>Platform to showcase your creativity and sharpen your skills</li>
                          <li>Opportunity to work with high profile international partners and artists</li>
                          <li>Ability to learn from key organization members and lead projects</li>
                          <li>A crash course on producing environmental and socially focused programming at major events, college and business campuses</li>
                          <li>Internship College Credit: We will provide documentation if needed and applicable for college internship credit</li>
                      </ul>

                      <p>The expected hours are <strong>10-15 hours per week</strong>. The hours required  will increase during event weeks (typically in the Spring and Summer months)</p>

                      <p>To <strong>apply</strong> for this position, please complete the job application form <a href="https://docs.google.com/forms/d/e/1FAIpQLSeSAzYsTmJNNHWU0OPvRiOv34CLxQ5afSCwmrLgU3FBqLYZ0Q/viewform" style={{'display': 'inline-block', 'textDecoration': 'underline'}} target="_blank">here</a>.</p>

                      <p><i>Please also note: This is an unpaid internship. Opportunity for pay in the future will vary on projects as well as skills/experience.</i></p>
                  </div>
              </div>
          </div>

          <div className="signup-accordion-container">
              <div className="signup-accordion" onClick={(e) => handleExpand(e)}>
                  <div className="signup-accordion-header">Event Management Internship</div>
                  <div className="signup-accordion-body">
                      <p><strong>Start Date - Dec 2022/Jan 2023 (Internship requires you to be present at first Coachella weekend in April 2023)</strong></p>
                      <p>Global Inheritance is looking for an Event Management Intern (Remote or Los Angeles Office Internship) to join our team.</p>

                      <p>The ideal applicant will be passionate about designing a better world, have excellent communication skills, be highly organized, detail-oriented, and have a dynamic personality that fits well with artists/partners/Global Inheritance team members. </p>

                      <p>Global Inheritance is a non-profit organization that develops experiences to educate and inspire individuals to take action around environmental and social issues. Our programs use the power of creativity and interactivity to empower people to join our mission in making this planet a better place for future generations. We are looking for a bright, creative, motivated, and organized individual to join the organization and make an impact.</p>

                      <p>
                      The successful candidate will be responsible for assisting hands-on with managing the logistics for various events, developing timelines, creating manifests for all necessary equipment and materials needed, ordering supplies, arranging for rentals, scheduling staff and volunteers, and communicating details to all personnel involved.
                      </p>

                      <ul className="signup-accordion-body-list">
                          <p>Responsibilities include but are not limited to:</p>
                          <li>Be a professional, friendly, and efficient first point of contact for staff, partners, suppliers and vendors.</li>
                          <li>Manage the process of acquiring all materials necessary for events, and creating staffing lists </li>
                          <li>Manage schedules, deadlines, and logistics for events. </li>
                          <li>Collaborate with Project Managers to design and develop engaging experiences for participants </li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>Desired Skills/Interests:</p>
                          <li>Extremely proactive. Excellent organizational skills - not afraid to take ownership of a project </li>
                          <li>Outstanding communicator</li>
                          <li>Comfortable being in a leadership role</li>
                          <li>Event planning experience</li>
                          <li>Awareness of the current trends within events </li>
                          <li>Interests in Social/Environmental Activism and ARTivism</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>Perks & Benefits:</p>
                          <li>Monthly travel credit for commuting in Los Angeles (office based internships)</li>
                          <li>Behind-the-scenes access to high profile events</li>
                          <li>Cool merchandise</li>
                          <li>Opportunity for paid event work</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>What You Receive:</p>
                          <li>Real world project experience on high-impact outreach campaigns</li>
                          <li>Meet like minded people in the Live Event/NGO/Entertainment/Art worlds</li>
                          <li>Platform to showcase your creativity and sharpen your skills</li>
                          <li>Opportunity to work with high profile international partners and artists</li>
                          <li>Ability to learn from key organization members and lead projects</li>
                          <li>A crash course on producing environmental and socially focused programming at major events, college and business campuses</li>
                          <li>Internship College Credit: We will provide documentation if needed and applicable for college internship credit</li>
                      </ul>

                      <p>The expected hours are <strong>10-15 hours per week</strong>. The hours required  will increase during event weeks (typically in the Spring and Summer months)</p>

                      <p>To <strong>apply</strong> for this position, please complete the job application form <a href="https://docs.google.com/forms/d/e/1FAIpQLSeSAzYsTmJNNHWU0OPvRiOv34CLxQ5afSCwmrLgU3FBqLYZ0Q/viewform" style={{'display': 'inline-block', 'textDecoration': 'underline'}} target="_blank">here</a>.</p>

                      <p><i>Please also note: This is an unpaid internship. Opportunity for pay in the future will vary on projects as well as skills/experience.</i></p>
                  </div>
              </div>
          </div>

          <div className="signup-accordion-container">
              <div className="signup-accordion" onClick={(e) => handleExpand(e)}>
                  <div className="signup-accordion-header">Partnership Strategist and Business Development Internship</div>
                  <div className="signup-accordion-body">
                      <p><strong>Start Date - Dec 2022/Jan 2023</strong></p>
                      <p>Global Inheritance is looking for a Partnership Strategist and Business Development Intern (Remote or Los Angeles Office Internship) to join our team.</p>

                      <p>The ideal applicant will be passionate about designing a better world, have excellent communication skills, be highly organized, detail-oriented, and have a dynamic personality that fits well with artists/partners/Global Inheritance team members. </p>

                      <p>Global Inheritance is a non-profit organization that develops experiences to educate and inspire individuals to take action around environmental and social issues. Our programs use the power of creativity and interactivity to empower people to join our mission in making this planet a better place for future generations. We are looking for a bright, creative, motivated, and organized individual to join the organization and make an impact.</p>

                      <p>
                      The successful candidate will be responsible for supporting our executive team with partnership management and outreach, fostering relationships with key individuals and companies, managing and building out our relationship database, sharing upcoming projects and campaign details with potential supporters, networking with professionals across several industries and supporting partner-driven events.
                      </p>

                      <ul className="signup-accordion-body-list">
                          <p>Responsibilities include but are not limited to:</p>
                          <li>Be a professional, friendly, and efficient first point of contact with potential partners - both on the phone and electronically</li>
                          <li>Review and expand our current list of partners based on upcoming projects and campaigns</li>
                          <li>Manage the process of archiving contacts created at events</li>
                          <li>After events, manage the delivery of any materials to key stakeholders </li>
                          <li>Provide campaign updates and relevant information with key contacts</li>
                          <li>Collaborate with Production Management Team to design and develop engaging experiences for participants and partners </li>
                          <li>Provide support to our creative and documentation teams at live and online events</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>Desired Skills/Interests:</p>
                          <li>Extremely proactive and an outstanding communicator</li>
                          <li>Excellent organizational skills - not afraid to take ownership of a project</li>
                          <li>Comfortable being in a leadership role</li>
                          <li>A practical knowledge of Microsoft or Apple office tools (spreadsheet, document, and presentation software)</li>
                          <li>Collaborate and make friends easily, eager to make new relationships in different industries </li>
                          <li>Track record of following through on projects</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>Perks & Benefits:</p>
                          <li>Monthly travel credit for commuting in Los Angeles (office based internships)</li>
                          <li>Behind-the-scenes access to high profile events</li>
                          <li>Cool merchandise</li>
                          <li>Opportunity for paid event work</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>What You Receive:</p>
                          <li>Real world project experience on high-impact outreach campaigns</li>
                          <li>Meet like minded people in the Live Event/NGO/Entertainment/Art worlds</li>
                          <li>Platform to showcase your creativity and sharpen your skills</li>
                          <li>Opportunity to work with high profile international partners and artists</li>
                          <li>Ability to learn from key organization members and lead projects</li>
                          <li>A crash course on producing environmental and socially focused programming at major events, college and business campuses</li>
                          <li>Internship College Credit: We will provide documentation if needed and applicable for college internship credit</li>
                      </ul>

                      <p>The expected hours are <strong>10-15 hours per week</strong>. The hours required  will increase during event weeks (typically in the Spring and Summer months)</p>

                      <p>To <strong>apply</strong> for this position, please complete the job application form <a href="https://docs.google.com/forms/d/e/1FAIpQLSeSAzYsTmJNNHWU0OPvRiOv34CLxQ5afSCwmrLgU3FBqLYZ0Q/viewform" style={{'display': 'inline-block', 'textDecoration': 'underline'}} target="_blank">here</a>.</p>

                      <p><i>Please also note: This is an unpaid internship. Opportunity for pay in the future will vary on projects as well as skills/experience.</i></p>
                  </div>
              </div>
          </div>

          <div className="signup-accordion-container">
              <div className="signup-accordion" onClick={(e) => handleExpand(e)}>
                  <div className="signup-accordion-header">POSTed Studio + Speaker Series Coordinator Internship</div>
                  <div className="signup-accordion-body">
                      <p><strong>Start Date - Dec 2022/Jan 2023 (Internship requires you to be on-site at both Coachella weekends April 2023)</strong></p>
                      <p>Global Inheritance seeks a POSTed Studio + Speaker Series Coordinator Intern to join our team. (Remote or Los Angeles Office Internship) </p>

                      <p>The ideal applicant will have excellent communication skills, be highly organized, detail-oriented, with a dynamic personality that fits well with artists, speakers, and Global Inheritance team members. </p>

                      <p>Global Inheritance is a non-profit organization that develops experiences to educate and inspire individuals to take action around environmental and social issues. Our programs use the power of creativity and interactivity to empower people to join our mission in making this planet a better place for future generations. We are looking for a bright, creative, motivated, and organized individual to join the organization and make an impact.</p>

                      <p>
                      The successful candidate will be the first point of contact for our POSTed Studio artists and speakers. They will manage deadline-driven creative design projects, and ensure the program is a fun and enriching experience for all participants.
                      </p>

                      <ul className="signup-accordion-body-list">
                          <p>Responsibilities include but are not limited to:</p>
                          <li>Be a professional, friendly, and efficient first point of contact for the artists - both on the phone and electronically</li>
                          <li>Review and expand our current list of potential artists and speakers</li>
                          <li>Manage the process of organizing databases and online  galleries, and archiving artwork created for events</li>
                          <li>Manage artist and speaker schedules, deadlines, and logistics of their participation in events</li>
                          <li>Manage the delivery of any materials to artists / participants / partners post-event</li>
                          <li>Provide campaign updates and information to event participants</li>
                          <li>Collaborate with social media team to develop promotional campaigns and featured artists content</li>
                          <li>Collaborate with Production Management Team to design and develop engaging experiences for participants</li>
                          <li>Provide support to our team at live and online events</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>Desired Skills/Interests:</p>
                          <li>Extremely proactive and outstandingly clear communicator</li>
                          <li>Excellent writing and grammar skills </li>
                          <li>Excellent organizational skills - not afraid to take ownership of a project</li>
                          <li>Comfortable being in a leadership role</li>
                          <li>A practical working knowledge of Canva and Adobe Creative Cloud software - especially Photoshop & Illustrator</li>
                          <li>Awareness of current trends within art and design</li>
                          <li>Interests in Social and Environmental Activism and ARTivism</li>
                          <li>An appreciation of art is necessary, having artistic abilities is desirable</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>Perks & Benefits:</p>
                          <li>Monthly travel credit for commuting in Los Angeles (office based internships)</li>
                          <li>Behind-the-scenes access to high profile events</li>
                          <li>Cool merchandise</li>
                          <li>Opportunity for paid event work</li>
                      </ul>

                      <ul className="signup-accordion-body-list">
                          <p>What You Receive:</p>
                          <li>Real world project experience on high-impact outreach campaigns</li>
                          <li>Meet like minded people in the Live Event/NGO/Entertainment/Art worlds</li>
                          <li>Platform to showcase your creativity and sharpen your skills</li>
                          <li>Opportunity to work with high profile international partners and artists</li>
                          <li>Ability to learn from key organization members and lead projects</li>
                          <li>A crash course on producing environmental and socially focused programming at major events, college and business campuses</li>
                          <li>Internship College Credit: We will provide documentation if needed and applicable for college internship credit</li>
                      </ul>

                      <p>The expected hours are <strong>10-15 hours per week</strong>. The hours required  will increase during event weeks (typically in the Spring and Summer months)</p>

                      <p>To <strong>apply</strong> for this position, please complete the job application form <a href="https://docs.google.com/forms/d/e/1FAIpQLSeSAzYsTmJNNHWU0OPvRiOv34CLxQ5afSCwmrLgU3FBqLYZ0Q/viewform" style={{'display': 'inline-block', 'textDecoration': 'underline'}} target="_blank">here</a>.</p>

                      <p><i>Please also note: This is an unpaid internship. Opportunity for pay in the future will vary on projects as well as skills/experience.</i></p>
                  </div>
              </div>
          </div> */}
          <div className="form-container application-form-container">
              <div className="form-inner application-form-inner">
                  <h1>Submit your application today!</h1>
                  <form name="applicationForm" className="form application-form app-intern" onSubmit={(e) => handleSubmit(e)}>
                      <input type="hidden" name="u" value="40e700f186279051f3a238f8a"/>
                      <input type="hidden" name="id" value="83440ad4be"/>
                      <input type="hidden" name="TYPE" id="MERGE3" value="INTERN" />
                      <div className="form-field application-field">
                          <input type="text" placeholder="First Name" name="FNAME" id="MERGE1" value={fName} onChange={(e)=> setFname(e.target.value)} />
                          <label>FIRST NAME</label>
                      </div>
                      <div className="form-field application-field">
                          <input type="text" name="LNAME" placeholder="Last Name" id="MERGE11" value={lName} onChange={(e)=> setLname(e.target.value)}/>
                          <label>LAST NAME</label>
                      </div>
                      <div className="form-field application-field">
                          <input type="email" name="EMAIL" placeholder="email" id="MERGE0" value={email}  onChange={ (e)=>setEmail(e.target.value) } />
                          <label>EMAIL</label>
                      </div>
                      <div className="form-field application-field">
                          <input type="text" name="PHONE" placeholder="phone" id="MERGE8" value={phone}  onChange={ (e)=>setPhone(e.target.value) }/>
                          <label>PHONE NUMBER</label>
                      </div>
                      <div className="form-field application-field">
                          <input type="text" name="VIDEOCHAT" placeholder="Video" id="MERGE6" value={skype}  onChange={ (e)=>setSkype(e.target.value) } />
                          <label>SKYPE/FACETIME ID</label>
                      </div>
                      <div className="form-field application-field">
                          <input type="text" name="FACEBOOK" placeholder="social" id="MERGE2" value={facebook}  onChange={ (e)=>setFacebook(e.target.value) } />
                          <label>FACEBOOK OR PHOTO URL</label>
                      </div>
                      <div className="form-field application-field">
                          <input type="text" name="RESUME" placeholder="Resume" id="MERGE7" value={resume}  onChange={ (e)=>setResume(e.target.value) } />
                          <label>LINK TO RESUME</label>
                      </div>
                      <div className="form-field application-field">
                          <input type="text" placeholder="How" name="SOURCE" id="MERGE4" value={source}  onChange={(e)=>setSource(e.target.value)}/>
                          <label>HOW DID YOU FIND OUT ABOUT US?</label>
                      </div>
                      <div className="form-field application-field">
                          <textarea name="WHY" placeholder="why" id="MERGE8" rows={1} cols={1} value={why} maxLength={255} onChange={(e) => setWhy(e.target.value)} />
                          <label>WHAT WOULD MAKE YOU A GOOD FIT? (Character limit 255)</label>
                      </div>

                      {/* <div className="form-field application-field">
                          <ul>
                              <p>Cities you can intern in?</p>
                              <li><input type="checkbox" className="city" ref="applicationFormCity" value="1" name="group[137][1]" id="mce-group[137]-137-0"/><span>Los Angeles</span></li>
                              <li><input type="checkbox" className="city" ref="applicationFormCity" value="2" name="group[137][2]" id="mce-group[137]-137-1"/><span>San Francisco</span></li>
                              <li><input type="checkbox" className="city" ref="applicationFormCity" value="4" name="group[137][4]" id="mce-group[137]-137-2" /><span>New York</span></li>
                              <li><input type="checkbox" className="city" ref="applicationFormCity" value="8" name="group[137][8]" id="mce-group[137]-137-3" /><span>Chicago</span></li>
                              <li><input type="checkbox" className="city" ref="applicationFormCity" value="16" name="group[137][16]" id="mce-group[137]-137-4" /><span>Miami</span></li>
                              <li><input type="checkbox" className="city" ref="applicationFormCity" value="32" name="group[137][32]" id="mce-group[137]-137-5" /><span>San Diego</span></li>
                          </ul>
                      </div> */}

                      {/* <div className="form-field application-field">
                          <ul>
                              <p>Which events would you be most interested in?</p>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="128" name="group[205][128]" id="mce-group[205]-205-0" /><span>US Open of Surfing</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="256" name="group[205][256]" id="mce-group[205]-205-1"/><span>FYF Fest</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="512" name="group[205][512]" id="mce-group[205]-205-2"/><span>Coachella</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="1024" name="group[205][1024]" id="mce-group[205]-205-3"/><span>Stagecoach</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="2048" name="group[205][2048]" id="mce-group[205]-205-4"/><span>Beyond Wonderland SoCal</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="4096" name="group[205][4096]" id="mce-group[205]-205-5"/><span>UCLA JRF Fest</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="8192" name="group[205][8192]" id="mce-group[205]-205-6"/><span>USC Spring Fest</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="16384" name="group[205][16384]" id="mce-group[205]-205-7"/><span>Made in America</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="32768" name="group[205][32768]" id="mce-group[205]-205-8"/><span>Firefly Music Festival</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="65536" name="group[205][65536]" id="mce-group[205]-205-9"/><span>Voodoo Music Festival</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="131072" name="group[205][131072]" id="mce-group[205]-205-10"/><span>Desert Daze</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="262144" name="group[205][262144]" id="mce-group[205]-205-11"/><span>Winter X Games</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="524288" name="group[205][524288]" id="mce-group[205]-205-12"/><span>Air + Style Los Angeles</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="2097152" name="group[205][2097152]" id="mce-group[205]-205-14"/><span>Life is Beautiful</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="4194304" name="group[205][4194304]" id="mce-group[205]-205-15"/><span>Form Arcosanti</span></li>
                              <li><input type="checkbox" className="event" ref="applicationFormEvent" value="8388608" name="group[205][8388608]" id="mce-group[205]-205-16"/><span>EDC LV</span></li>
                          </ul>
                      </div> */}

                      <div className="submit-container">
                          <button type="submit" className="btn-container btn primary">
                              <span>Submit</span>
                          </button>
                      </div>

                      <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                          <label htmlFor="b_name">Name: </label>
                          <input type="text" name="b_name" tabIndex={-1} value="" placeholder="Freddie" id="b_name"/>

                          <label htmlFor="b_email">Email: </label>
                          <input type="email" name="b_email" tabIndex={-1} value="" placeholder="youremail@gmail.com" id="b_email"/>

                          <label htmlFor="b_comment">Comment: </label>
                          <textarea name="b_comment" tabIndex={-1} placeholder="Please comment" id="b_comment"></textarea>
                      </div>

                      <div className={`contact-message ${feedback.messageType}`}>{feedback.message}</div>
                  </form>
              </div>
          </div>
          </div>
      </div>
      </div>
      </Layout>
  )
}

export default SignUpIntern;
